@keyframes fold-line-arrow
{
    0%, 100%, 40%
    {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    
    25%, 60%
    {
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
}

#news-slider
{
    .slick-arrow
    {
        display: none !important;
    }
}

.home-col
{
    display: flex;
    flex-direction: column;

    &>.content
    {
        flex: 1 0 auto;
    }
}

.half-box-outer
{
    display: flex;
}

.half-box
{
    align-items: stretch;
    width: 100%;
    padding: 30% 0;
    position: relative;
    
    .half-box-inner
    {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

#arrow
{
    display: none;

    @media (min-width: map-get($grid-breakpoints, md))
    {
        position: fixed;
        right: 2%;
        bottom: 35px;
        padding: 0 10px;
        z-index: 10;
        color: $red;
        cursor: pointer;
        animation: .8s infinite fold-line-arrow;

        &.visible
        {
            display: block;
        }
    }

    &:hover
    {
        color: darken($red, 25%);
    }
}
