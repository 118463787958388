/*
 * Na poczatku dodany jest common/reset i dzialalo to z bootstrap 4.0 beta.
 * Dalej dodany jest bootstrap/scss/reboot, ktory troche to wszystko psuje
 * a ja nie mam teraz czasu, zeby to naprawic porzadnie, dlatego wstawiam ten plik.
 * 
 * Docelowo trzeba sie go pozbyc, razem z common/reset!!!
 */
ul
{
    margin: 0;
}

.btn
{
    font-family: $font-family-2;
    font-weight: bold;
    text-transform: uppercase;
}

 .btn-default
{
    @include button-variant(#000, #000, lighten(#000, 10%), lighten(#000, 10%));
}

.btn-fw
{
    width: 100%;
}

.btn-sm
{
    font-size: 11px;
}

.custom-control-label
{
    &::after
    {
        height: ($custom-control-indicator-size - 0.05);
    }
}
