.border-select {
    position: relative;
    select {
        outline: none;
        -webkit-appearance: none;
        font-size: 11px;
        text-transform: uppercase;
        padding: 12px 30px;
        -moz-border-radius: 0px 0px 0px 0px;
        -webkit-border-radius: 0px 0px 0px 0px;
        border-radius: 0px 0px 0px 0px;
        width: 100%;
        background: transparent;
    }
    &.border-select-sm {
        select {
            padding: 6px 15px;
        }
    }

    &:before {
        position: absolute;
        content: "\25bc";
        top:calc(50% - 2px);
        right:15px;
        display: block;
        font-size: 6px;
        color:#333;
    }

}