.article
{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: $text-color;
    margin-bottom: 45px;

    a
    {
        text-decoration: underline;

        &:hover
        {
            text-decoration: none;
        }

        &.btn
        {
            text-decoration: inherit;
        }
    }

    h1, h2, h3
    {
        font-family: $font-family-2;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 0.3em;

        a
        {
            text-decoration: none;

            &:hover
            {
                text-decoration: underline;
            }
        }
    }

    h1
    {
        font-size: 36px;
    }

    h2
    {
        font-size: 24px;
    }

    h3
    {
        font-size: 18px;
    }

    p
    {
        margin-bottom: 10px;
    }

    ol, ul
    {
        padding-left: 40px;
        margin-bottom: 10px;
        line-height: 1.4285;
    }

    ul
    {
        list-style-type: disc;
    }

    ol
    {
        list-style-type: decimal;
    }

    .subtitle
    {
        font-size: 16px;
        color: $text-color-3;
    }
}
