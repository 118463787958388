a
{
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    outline:none;

    &:hover
    {
        text-decoration: none;
    }
}

html, body
{
    font-family: $font-family;
    position: relative;
    width:100%;
    height:100%;
    font-size: 14px;
    color: $text-color;
    letter-spacing: 0.2px;

    &.lock
    {
        overflow: hidden;
        //position: fixed;
    }
}

body
{
    padding: 50px 0 0;
}

strong
{
    font-weight: 700;
}

.text-center
{
    text-align: center;
}

.text-right
{
    text-align: right;
}

.buttons
{
    margin: 15px auto;
    text-align: center;
}

.spacer
{
    height: 1px;
    width: 100%;
    background: $border-color;;
    margin-top:30px;
    margin-bottom:30px;
    text-align: center;

    span
    {
        background: #FFF;
        padding: 0px 10px;
        text-align: center;
        font-size: 11px;
        margin-top: -6px;
        display: inline-block;
        color: $text-color-3;
        text-transform: uppercase;
    }
}

.header
{
    margin-top:45px;
    font-family: $font-family-2;
    margin-bottom: 45px;

    .title
    {
        margin-bottom: 2px;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #000;
        margin-bottom: 2px;
    }

    h1.title
    {
        font-size: 32px;
    }
    
    .subtitle
    {
        font-size: 14px;
    }

    a.subtitle:hover
    {
        text-decoration: underline;
    }

    .description
    {
        font-size: 12px;
        line-height: 1.5em;

        a
        {
            color: $color-accent;
        }
    }
}

.lead
{
    font-size: 17px;
}

.popover-body
{
    a
    {
        text-decoration: underline;

        &:hover
        {
            text-decoration: none;
        }
    }
}

@media (min-width: ($header-mobile-breakpoint + 1))
{
    body
    {
        padding: 0;
    }
}
