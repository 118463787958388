.info-page {
    margin-bottom: 30px;

    .narrow {
        max-width: 960px;
        margin:0px auto;
    }
    
    & > .heading {
        padding: 30px 0px;
        //background: #f5f5f5;
        text-align: center;

        .help-title {
            color: lighten($text-color-3, 15%);
            font-size: 15px;
            margin-bottom: 15px;
        }

        .title {
            font-family: $font-family-2;
            font-weight: 600;
            font-size: 36px;
            color: $text-color;
            margin-bottom: 10px;
        }

        .subtitle {
            color: $text-color-3;
            font-size: 16px;
        }

        nav {
            margin: 25px auto 15px;
            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    margin: 8px;
                    &.active {
                        a {
                            color: darken($color-accent, 15%);
                        }
                    }
                    a {
                        font-size: 15px;
                        color: $color-accent;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    
}