#contact {

    > .heading {
        padding-bottom: 45px;
    }

    .contact-common {
        font-size: 14px;
        font-family: $font-family;
        font-weight: 400;
        color: $text-color-2;
        line-height: 1.5em;
        margin: 0px auto 30px;
        padding: 5px;

        .title {
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    #map {
        min-height: 450px;
        background: #EEE;
    }


}