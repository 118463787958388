#account {

    //border: 1px solid $border-color;
    //@include border-radius(5px);
    margin-bottom: 30px;

    //padding: 30px;

    .form {
        margin: 0px auto 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #DDD;
        padding: 30px;
    }


    
}