@mixin border-radius($border-radius: 0px) {
    -webkit-border-radius: $border-radius;
    -moz-border-radius: $border-radius;
    border-radius: $border-radius;
}

@mixin border-radius2($top-left: 0px, $top-right: 0px, $bottom-left: 0px, $bottom-right: 0px) {
    -webkit-border-top-left-radius: $top-left;
    -webkit-border-top-right-radius: $top-right;
    -webkit-border-bottom-right-radius: $bottom-right;
    -webkit-border-bottom-left-radius: $bottom-left;
    -moz-border-radius-topleft: $top-left;
    -moz-border-radius-topright: $top-right;
    -moz-border-radius-bottomright: $bottom-right;
    -moz-border-radius-bottomleft: $bottom-left;
    border-top-left-radius: $top-left;
    border-top-right-radius: $top-right;
    border-bottom-right-radius: $bottom-right;
    border-bottom-left-radius: $bottom-left;
}