.survey-answers
{
    margin-bottom: 2rem;
}

.survey-image
{
    border-radius: 4px;
}

.survey-answer-name
{
    font-size: 1.5rem;
    margin: .5rem;
}

.survey-answer-counter
{
    font-weight: bold;
}
