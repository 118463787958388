.checkout {

    border: 1px solid $border-color;
    @include border-radius(5px);
    margin-bottom: 30px;

    .checkout-step {
        position: relative;
        //padding: 45px;

        
        .spacer-vertical {
            width: 1px;
            height: 100%;
            display: block;
            background: $border-color;
            position: absolute;
            top:0px;
            right: 50%;
            span {
                padding: 10px;
                background: #FFF;
                top:calc(50% - 16px);
                left:-18px;
                position: absolute;
                font-size: 12px;
                color: $text-color-2;
            }
            @media only screen and (max-width: $header-mobile-breakpoint ) {
                display: none;
            }
        }

        .btn-edit {
            margin-top: 6px;
            margin-bottom: 0px;
        }

        

    }

    .checkout-shipping-method-price {
        font-size: 20px;
        font-weight: bolder;
        color: $text-color-3;
        line-height: 48px;
        text-align: right;
    }

}

.checkout-address
{
    .custom-control-label
    {
        font-size: 15px;
        font-weight: 400;
        color: #666;
    }
}

.checkout-summary {
    background: #FAFAFA;
    padding: 30px;
    margin-bottom: 30px;

    @media only screen and (max-width: $header-sm-mobile-breakpoint) {
        border: 1px solid $border-color;
    }

    /*.heading {
        .title {
            font-size: 16px;
            font-weight: bolder;
            color: $text-color;
        }
    }*/

    .spacer {
        margin: 15px 0px;
    }

    .totals {
        font-size: 16px;
        font-weight: bolder;
        color: $text-color-2;
        margin-bottom: 15px;

        .total {
            margin-bottom: 15px;
        }

        &.big {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .buttons {
        text-align: right;
    }

    .checkout-product {
        font-size: 12px;
        margin-bottom: 10px;
        .checkout-product-image {
            img {
                width: 100%;
            }
        }
        .price {
            font-size: 12px;
            text-align: right;
            color: $text-color-2;
        }
        @media only screen and (max-width: $header-sm-mobile-breakpoint) {
            font-size: 11px;
            .price {
                font-size: 11px;
            }
        }
    }

}

.modal-order-confirm
{
    @media screen and (min-width: 1000px) {
        max-width: 800px;
    }

    .modal-body>.row
    {
        margin: 20px 0;
    }
}

.order_confirm__shipping
{
    margin-top: 0 !important;
}

.order_confirm__shipping_col
{
    margin-bottom: 10px;

    h3
    {
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.order_confirm__products
{
    overflow-x: auto;
    overflow-y: hidden;
}

.order_confirm__products_table
{
    display: table;
    min-width: 100%;
    width: auto;
}

.order_confirm__products_row
{
    display: table-row;

    &>div
    {
        padding: 5px;
    }
}

.order_confirm__products_row_header
{
    font-weight: bold;
}

.order_confirm__product_name,
.order_confirm__product_count,
.order_confirm__product_price,
.order_confirm__product_total
{
    display: table-cell;
}

.order_confirm__product_name
{
    width: 60%;
    min-width: 220px;
}

.order_confirm__product_name_name
{
    margin: 0;
}

.order_confirm__product_name_option
{
    color: #9e9e9e;
    font-size: smaller;
}

.order_confirm__product_count
{
    width: 10%;
    min-width: 50px;
    text-align: right;
}

.order_confirm__product_price,
.order_confirm__product_total
{
    width: 15%;
    min-width: 110px;
    text-align: right;
}

.order_confirm__summary
{
    display: block;
}

.order_confirm__summary_text
{
    padding: 5px;
    text-align: right;
    font-weight: bold;

    >span
    {
        display: block;
        
        @media (min-width: 576px) {
            display: inline-block;
            width: 15%;
            min-width: 110px;
        }
    }
}

.checkout-shipping-info-box
{
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
    background: #fff;
}

.checkout-shipping-info
{
    padding: 10px 0;
    color: #666;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}
