.filters {

    & > .title {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .filter {
        width:100%;
        //border: 1px solid #EEE;
        //padding: 10px;
        margin-bottom: 15px;

        .title {
            font-size: 12px;
            color: $text-color-2;
        }

        .custom-select
        {
            text-align: left;
        }

        .select.border-select {
            select {
                border: 1px solid $border-color;
                color: #666;
            }
        }
    }
}

.filters-dropdown-list
{
    max-height: 40vh;
    overflow-y: auto;
    margin-top: ($dropdown-padding-y * -1);
}

.filters-dropdown-footer
{
    margin: 0 0 ($dropdown-padding-y * -1);
}

.filter
{
    .dropdown-item
    {
        margin: 0;
    }
}

.btn-do-filter
{
    &:hover
    {
        background: $primary;
        color: lighten($primary, 50%);
    }
}

#btnRemoveFilters,
.filter-applied
{
    float: left;
    padding: 3px 8px;
    margin-right: 5px;
    margin-bottom: 5px;
 
    cursor: pointer;

    font-size: smaller;

    background: $primary;
    color: #fff;

    border-radius: 2px;

    &:hover
    {
        background: darken($primary, 10%);
    }

    &:after
    {
        content: '×';
        // color: $color-accent-2;
        padding-left: 3px;
    }
}

#btnRemoveFilters
{
    background: #000;

    &:hover
    {
        background: lighten(#000, 10%);
    }

    &:after
    {
        color: #fff;
    }
}
