#orders {

    max-width: 640px;
    margin:0px auto 45px;

    .heading {
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #DDD;
        .title {
            font-size: 16px;
            font-family: $font-family-2;
            font-weight: 600;
        }
    }

    .order-list {
        margin-bottom: 30px;
    }

    .order {
        display: block;
        padding: 15px;
        border: 1px solid #EEE;
        @include border-radius(5px);
        margin-bottom: 10px;

        &:hover {
            background: #F3F3F3;
        } 

        .id {
            font-size: 28px;
            color: lighten($text-color-3, 15%);
            font-weight: 600;
            font-family: $font-family-2;
            text-align: center;
        }
        .status {
            padding: 5px;
            border: 1px solid;
            @include border-radius(3px);
            text-align: center;
            font-size: 12px;
            margin-top: 3px;

            &.info {
                border-color: $color-accent-2;
                color: $color-accent-2;
            }
            &.success {
                border-color: $color-accent;
                color: $color-accent;
            }
            &.danger {
                border-color: $color-danger;
                color: $color-danger;
            }
        }

        .details {
            font-size: 12px;
            color: $text-color-2;
            div {
                margin-bottom: 2px;
            }
            span {
                color: $text-color-3;
            }
        }
        .total {
            .label {
                font-size: 11px;
                color: $text-color-3;
            }
            .value {
                font-size: 14px;
                color: $text-color-2;
            }
        }
    }

}