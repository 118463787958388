#order
{
    max-width: 640px;
    margin: 0px auto 45px;
    font-size: 16px;

    .heading
    {
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #DDD;

        .title
        {
            font-family: $font-family-2;
            font-weight: 600;
        }
    }

    .section-title
    {
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #EEE;

        .title
        {
            font-family: $font-family-2;
            font-weight: 600;
        }
    }

    .address
    {
        color: $text-color-2;
        margin-bottom: 30px;
    }

    .details
    {
        margin-bottom: 30px;

        .id
        {
            font-size: 24px;
            color: lighten($text-color-3, 15%);
            font-weight: 600;
            font-family: $font-family-2;
            margin-bottom: 15px;

            & > span
            {
                color: $text-color-2;
            }
        }

        .detail
        {
            margin-top: 3px;
            margin-bottom: 15px;

            .label
            {
                font-family: $font-family-2;
                font-size: 12px;
                color: $text-color-3;
                padding: 0;
            }

            .value
            {
                color: $text-color-2;
            }
        }
    }

    .products
    {
        padding-top: 15px;
        margin-bottom: 30px;
        font-family: $font-family-2;

        .product
        {
            margin-bottom: 15px;

            .name
            {
                font-family: $font-family-2;
                font-weight: bold;
                color: $text-color-2;
            }

            .variant
            {
                font-size: 12px;
                color: $text-color-2;
            }

            .quantity
            {
                color: lighten($text-color-3 , 20%);
                font-family: $font-family-2;
                font-weight: bold;
                text-align: right;
            }

            .total
            {
                color: lighten($text-color-2 , 20%);
                font-family: $font-family-2;
                font-weight: bold;
                text-align: right;
            }
        }
    }

    .totals
    {
        padding-top: 15px;
        margin-bottom: 30px;
        font-family: $font-family-2;

        .total
        {
            margin-bottom: 10px;

            .name
            {
                font-family: $font-family;
                text-align: right;
                color: $text-color-2;
            }

            .value
            {
                color: lighten($text-color-2 , 20%);
                font-family: $font-family-2;
                font-weight: bold;
                text-align: right;
            }

            &:last-child
            {
                .value
                {
                    color: lighten($text-color , 20%);
                }
            }
        }
    }


    .statuses
    {
        padding-top: 15px;
        margin-bottom: 30px;

        .status
        {
            margin-bottom: 15px;

            &>.row:hover
            {
                background: #efefef;
            }

            .date
            {
                font-size: 16px;
                color: $text-color-3;
            }

            .name
            {
                font-size: 16px;
                color: $text-color;
            }

            .comment
            {
                font-size: 14px;
                color: $text-color-2;
            }
        }
    }
}
