.pagination {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    & > a,
    & > span {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        width: 32px;
        height: 32px;
        text-align: center;
        margin-left:5px;
        font-size: 18px;
        color: $text-color-3;
    }
    & > a {
        &.arrow {
            font-size: 32px;
            font-weight: bold;
            span {
                height: 20px;
                line-height: 16px;
            }
        }
        &:hover,
        &.active {
            color: $text-color;
        }
    }
}