.breadcrumbs {
    margin-top: 15px;
    margin-bottom: 15px;
    ul {
        &:after {
            clear: both;
            content: "";
            display: block;
        }
        li {
            font-size: 12px;
            float: left;
            margin-right: 5px;
            color: #666;
            &:after {
                content: "\00bb";
                padding-left:5px;
            }
            &:last-child{
                color:#333;
                &:after {
                    content: "";
                }
            }
            a:hover {
                text-decoration: underline;
            }
        }
    }
}