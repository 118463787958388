.videos
{
    padding: 60px 0px;
    background: #2b2b2b;

    margin-top: 60px;
    margin-bottom: 60px;

    .title
    {
        font-family: $font-family-2;
        font-size: 32px;
        color:#DDD;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    
    .video-list-bg
    {
        background: #CCC;
    }
}

.video-list
{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    &:hover
    {
        opacity: 0.8;
    }

    &.active
    {
        opacity: 1;
    }

    .video-in-list
    {
        background: #000;
        display: block;
        opacity: 0.5;

        .video-in-list-title
        {
            font-family: $font-family-2;
            padding: 17px;
            font-size: 12px;
            line-height: 1.2em;
            letter-spacing: 0.5px;
            color: #DDD;
        }

        .video-in-list-thumb
        {
            img
            {
                width: 100%;
            }
        }
    }

    .video-more
    {
        background: #000;
        display: block;
        opacity: 0.5;
        padding: 15% 0% 0;
        position: relative;

        &:hover
        {
            opacity: 1;
        }

        .text
        {
            position: absolute;
            top: calc(50% - 8px);
            left: 0px;
            height: 16px;
            line-height: 16px;
            font-size: 11px;
            width: 100%;
            text-align: center;
            color:#FFF;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
}

.main-video
{
    display: block;
    position: relative;
    z-index: 1;

    &:hover
    {
        .video-thumb
        {
            opacity: 0.8;
        }

        .play-icon
        {
            width: 100px;
            height: 100px;
            top: calc(50% - 50px);
            left: calc(50% - 50px);
        }
    }

    &.embed
    {
        padding: 28% 0px;
        background: #111;
    }

    iframe
    {
        position: absolute;
        width: 100%; height: 100%;
        top: 0px; left: 0px;
    }

    .play-icon
    {
        position: absolute;
        z-index: 3;
        width: 96px;
        height: 96px;
        top: calc(50% - 48px);
        left: calc(50% - 48px);
        transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s;
        -webkit-transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s;
        -ms-transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s;
    }

    .video-thumb
    {
        position: relative;
        z-index: 2;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        -ms-transition: opacity 0.3s;
        padding-bottom: 56%;
        overflow: hidden;

        img
        {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }
}
