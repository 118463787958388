.form-panel {
    
    margin:15px auto;
    padding:45px;
    &.narrow {
        max-width: 480px;
    }

    @media only screen and (max-width: $header-sm-mobile-breakpoint) {
        padding:15px;
    }
}

.segment {
    padding: 5px;
    //background: $segment-grey;
    @include border-radius(5px);
    border: 4px solid #F3F3F3;
    background: #f7f7f7;
    &.pad {
        padding: 15px;
    }
}

.radio-list
{
    margin-bottom: -10px;
}

.radio-list-entry
{
    margin-bottom: 10px;
    padding: 15px;
    @include border-radius(5px);

    img
    {
        width: 100%;
        max-width: 100%;
        opacity: 0.5;
        transition: opacity 0.5s;
        -webkit-transition: opacity 0.5s;
        -ms-transition: opacity 0.5s;
    }

    &.selected
    {
        background: #FFF;
        transition: background-color 0.5s;
        -webkit-transition: background-color 0.5s;
        -ms-transition: background-color 0.5s;
        
        img
        {
            opacity: 1;
        }
    }

    .custom-control
    {
        // height: 48px;

        .custom-control-description
        {
            flex-direction: column;
        }
    }
}
    
.form-default {
    input,
    select,
    .custom-checkbox,
    textarea
    {
        margin-top: 10px;
    }

    .buttons
    {
        margin-bottom: 0px;
    }

    .group-heading
    {
        margin-bottom: 10px;

        .title
        {
            font-size: 15px;
            font-family: $font-family-2;
            color: $text-color-2;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .subtitle
        {
            font-size: 13px;
            font-family: $font-family-2;
            color: $text-color-2;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .description
        {
            font-size: 13px;
            font-family: $font-family;
            color: $text-color-2;
            line-height: 1.5em;

            strong
            {
                font-weight: 900;
            }
        }
    }

    .input-label
    {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .custom-file
    {
        width: 100%;
    }

    .custom-file-control
    {
        overflow: hidden;

        &:lang(pl)::before
        {
            content: "Wybierz";
        }

        &:lang(pl)::after
        {
            content: "Wybierz plik...";
        }

        &.selected:lang(pl)::after
        {
            content: "" !important;
        }
    }

    .error
    {
        color: $color-danger;
        margin-bottom: 5px;
        margin-top: 5px;
        display: block;
        font-size: 11px;
    }

    textarea
    {
        min-height: 100px;
    }   

    .form-section-title
    {
        font-weight: bold;
        font-size: 12px;
        margin-top: 15px;
    }

    .custom-control-label
    {
        font-size: 11px;
        font-weight: bold;
    }

    .custom-control
    {
        padding-left: 1.75rem;
        align-items: center;

        .custom-control-indicator
        {
            top: calc(50% - 8px);
            width: 16px;
            height: 16px;
            transition: background-color 0.5s, background-size 0.1s;
            -webkit-transition: background-color 0.5s, background-size 0.1s;
            -ms-transition: background-color 0.5s, background-size 0.1s;

            background-size: 0% 0%;
        }

        .custom-control-input:checked ~ .custom-control-indicator
        {
            background-size: 50% 50%;
        }

        .custom-control-description
        {
            font-size: 11px;
            font-weight: bold;
            display: flex;
            
            a
            {
                margin:0px 2px;
                color: $color-accent;
            }

            &.big
            {
                font-size: 15px;
                font-weight: 400;
                line-height: 1.2em;
                color: $text-color-2;

                small
                {
                    font-size: 0.85em;
                    color: $text-color-3;
                }

                @media only screen and (max-width: $header-sm-mobile-breakpoint)
                {
                    font-size: 13px;
                }
            }
        }
    }
}

.custom-control
{
    a
    {
        color: $color-accent;

        &:hover
        {
            text-decoration: underline;
        }
    }
}

a.tip
{
    font-size: 12px;
    color:$color-accent;

    &:hover
    {
        text-decoration: underline;
    }
}

.input-group-append .btn
{
    padding: $input-padding-y $input-padding-x;
}
