.technical-tips
{
    font-size: 16px;

    a
    {
        text-decoration: underline;

        &:hover
        {
            text-decoration: none;
        }
    }

    table
    {
        font-size: 14px;
    }

    td
    {
        font-family: monospace;
    }

    h1, h2, h3, h4
    {
        font-family: $font-family-2;
        font-weight: 600;
        margin: 16px 0 24px;
        text-align: center;
        line-height: 1.0;
    }
    
    h1
    {
        font-size: 32px;
        margin-top: 32px;
        margin-bottom: 42px;
    }
    
    h2
    {
        font-size: 28px;
        margin-bottom: 18px;
    }
    
    h3
    {
        font-size: 24px;
        margin-bottom: 16px;
    }
    
    h4
    {
        font-size: 20px;
        margin-bottom: 12px;
    }

    ol
    {
        list-style-type: decimal;
        padding-left: 2.5em;
    }

    ul
    {
        list-style-type: disc;
        list-style-position: outside;
        padding-left: 40px;
    }

    li
    {
        > p
        {
            margin-bottom: 0;
        }
    }

    .accordion
    {
        h3
        {
            margin: 0;
        }
    }

    .btn
    {
        text-decoration: inherit;

        &:focus
        {
            box-shadow: none;
        }
    }

    .lead
    {
        font-size: 20px;
    }

    .nav-parent
    {
        margin-bottom: 64px;
    }

    .nav-responsive
    {
        background-color: rgba(0,0,0,.9);
        list-style-type: none;
    }

    .nav-link
    {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
    }

    .pros, .cons
    {
        padding: 0;
        list-style-type: none;

        > li::before
        {
            margin-right: 5px;
            font-family: monospace;
            font-weight: bold;
        }
    }

    .pros > li::before
    {
        content: '+';
        color: green;
    }

    .cons > li::before
    {
        content: '-';
        color: red;
    }

    .nav-btn
    {
        width: 100%;
    }

    .list-group-responsive
    {
        flex-direction: row;
        justify-content: space-around;

        .list-group-item
        {
            background-color: $color-accent;
            color: #fff;
            border: 0;

            &.active
            {
                background-color: darken($color-accent, 15%);
            }
        }

        a
        {
            text-decoration: none;
        }
    }

    .container-even-odd
    {
        > .row
        {
            margin-top: 52px;
            margin-bottom: 52px;

            
            @include media-breakpoint-up(md)
            {
                &:nth-child(even)
                {
                    > div:nth-child(2)
                    {
                        order: -1;
                    }
                }

                > div:nth-child(2)
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .see-also
    {
        font: 600 1em $font-family;
        text-align: left;
        margin: 0;
        color: lighten(#000, 20%);
    }

    .bar-material
    {
        margin-bottom: 32px;

        h4
        {
            text-align: left;
            font: bold 16px $font-family;
        }
    }

    .bar-material-system
    {
        display: flex;
        list-style-position: inside;
        padding: 0;

        &>li
        {
            flex-grow: 1;
        }
    }

    .row-cs
    {
        margin-top: 54px;
        margin-bottom: 54px;
    }

    .compression-system
    {
        margin-bottom: 32px;

        .header-pc
        {
            text-align: left;
            font: bold 16px $font-family;
        }
    }

    .row-2col
    {
        margin-top: 54px;
        margin-bottom: 54px;
    }

    .tooltip-mark
    {
        text-decoration: underline dotted;
        cursor: help;
    }

    @include media-breakpoint-up(md)
    {
        .nav-responsive
        {
            justify-content: center;
            background-color: transparent;
        }

        .nav-link
        {
            text-transform: none;
            font-weight: normal;
            color: $color-accent;

            &.active, &:hover
            {
                color: darken($color-accent, 15%);
            }
        }

        .list-group-responsive
        {
            flex-direction: column;
        }

        .tab-pane > h3
        {
            text-align: left;
        }

        .row-cs
        {
            .compression-system+div
            {
                display: flex;
                justify-content: center;
            }
        }

        .row-2col
        {
            h2
            {
                margin-top: 0;
                text-align: right;
            }
        }
    }
}
