.nav-parent
{
    display: flex;
    justify-content: center;
    align-items: center;

    &.open
    {
        .nav-responsive
        {
            bottom: 0;
            z-index: 8;
        }
    }
}

.nav-responsive
{
    display: flex;
    position: fixed;
    bottom: -100%;
    width: 100%;
    max-height: 50vh;
    z-index: -1;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: scroll;
    list-style: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    margin: 0;
    padding: 0;
    background: #f3f3f3;
    transition: bottom .3s;
}

@include media-breakpoint-up(lg)
{
    .nav-btn
    {
        display: none;
    }

    .nav-responsive
    {
        display: flex;
        position: initial;
        flex-direction: row;
        border: 0;
        box-shadow: none;
        background: transparent;
        z-index: inherit;
        overflow-y: initial;
        max-height: initial;
    }

    .nav-parent.open .nav-responsive
    {
        position: initial;
        transform: none;
        width: auto;
    }
}
