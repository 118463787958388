#cart
{
    margin-bottom: 45px;
}

.cart-products
{
    border-bottom: 1px solid #333;
}

.cart-product
{
    border-top: 1px solid $border-color;

    padding-top: 30px;
    padding-bottom: 30px;

    .cart-product-image
    {
        img
        {
            width: 100%;
        }
    }

    &.set
    {
        position: relative;
    }

    .qnt
    {
        display: flex;

        button
        {
            border: 1px solid #999;
            background: #fff;
            text-align: center;
            font-family: monospace;
            padding: 5px 15px;
        }

        input
        {
            border: 0;
            border-top: 1px solid #999;
            border-bottom: 1px solid #999;
            text-align: center;
            width: 55px;
        }

        .quantity-minus
        {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .quantity-plus
        {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.cart-product-details
{
    .product-name
    {
        font-family: $font-family-2;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px;

        @media only screen and (max-width: $header-sm-mobile-breakpoint)
        {
            font-size: 16px;
        }
    }

    .product-brand
    {
        font-size: 11px;
        font-family: $font-family-2;
        text-transform: uppercase;
        font-weight: bold;
        color: #999;
        margin-bottom: 2px;
    }

    .product-variant
    {
        font-size: 14px;
        font-family: $font-family-2;
        color: #999;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 15px;
    }

    .product-quantity
    {
        margin-bottom: 10px;
        width: 80px;

        .title
        {
            font-size: 12px;
        }
    }

    .product-price {
        .title {
            font-size: 12px;
        }
    }
}

.productset-quantity
{
    color: #999;

    &::after
    {
        content: '×';
        // font-size: 13px;
    }
}

.product-remove {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    padding: 0 5px;
    font-size: 24px;
    font-family: $font-family-2;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
}

.cart-summary
{
    margin-top:30px;
}

.cart-totals
{
    background: #F3F3F3;
    @include border-radius(5px);
    padding: 15px;

    .sub-total
    {
        border-bottom: 1px solid $border-color;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        color: $text-color-2;

        .sub-total-value
        {
            text-align: right;
        }
    }

    .total {
        margin-top:15px;
        font-size: 18px;
        color: $text-color;
        margin-bottom:30px;
        .total-value {
            text-align: right;
            color: #000;
        }
    }
    .buttons {
        margin-top:30px;
        margin-bottom:0px;
        .btn {
            display: block;
            line-height: 32px;
            &.btn-back {
                font-size: 14px;
            }
        }
        @media only screen and (max-width: $header-sm-mobile-breakpoint) {
            .row>.col-12:first-child {
                order: 1;
            }
        }
    }

}
