$font-family: 'Lato', sans-serif;
$font-family-2: 'Montserrat', sans-serif;

$header-height: 90px;
$header-top-height: 24px;

$header-mobile-height: 50px;
$header-mobile-breakpoint: 991px;
$header-sm-mobile-breakpoint: 576px;

$red: #e62218;
$cyan: #17a2b8;

$color-accent: #00b321; // #00e331; // #1fd6a8; //#5BE7C4; //rgb(4, 187, 155);
$color-accent-2: #000000; // #1aa2d1; //#4FC1E9; //rgb(187, 155, 4);
$color-accent-3: #7A57D1; //#7A57D1; //rgb(155, 4, 187);
$color-accent-4: #ffc631; //#FFD464; //rgb(187, 4, 155);
// $color-promo: #f53838; // d15757
$color-promo: $red;
// $color-danger: #d53838;
$color-danger: $red;
// $danger: $color-danger;
$color-success: $color-accent;

$border-color: #DDD;
$segment-grey: #F3F3F3;

$text-color: #333;
$text-color-2: #666;
$text-color-3: #999;
$text-color-black: #121212;

$main-menu-color: #000;
$main-menu-text-color: #FFF;

$primary: $color-accent;
$secondary: $color-accent-2;

$btn-padding-x: 1.3rem;
$btn-padding-y: 0.6rem;
$btn-padding-x-sm: 0.8rem;
$btn-padding-y-sm: 0.6rem;

$link-decoration: none;
$link-hover-color: inherit;

$tooltip-opacity: 1;

$grid-gutter-width: 16px;

$custom-control-indicator-size: 1.2rem;
