#stickers {

    h5, h6 {
        font-family: $font-family-2;
        color: $text-color-3;
        font-weight: 500;
        text-align: center;
        margin: 45px auto;
        max-width: 640px;
        line-height: 1.2em;
    }
    h5 {
        font-size: 24px;
    }
    h6 {
        font-size: 16px;
    }

    .narrow {
        max-width: 520px;
    }

    #steps {
        margin-top: 60px;
        margin-bottom: 60px;
        .step {
            margin-top: 45px;
            margin-bottom: 45px;

            > .row {
                align-items: center;
            }

            .num {
                font-size: 90px;
                color: $border-color;
                font-weight: 700;
                font-family: $font-family-2;
            }
            .instruction {
                font-size: 24px;
                color: $text-color-3;
                font-weight: 400;
                font-family: $font-family-2;

                .address {
                    margin-top: 5px;
                    font-size: 16px;
                }
            }
        }
    }
    

}