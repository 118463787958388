.list-pros, .list-cons
{
    list-style-type: none;
    
    .header
    {
        font: bold 15px $font-family;
        text-align: center;
        margin: 2px 0;
        color: gray;
    }

    li::before
    {
        margin-right: 5px;
    }
}

.list-pros
{
    ul
    {
        color: green;
    }

    li::before
    {
        content: '+';
    }
}

.list-cons
{
    ul
    {
        color: red;
    }

    li::before
    {
        content: '-';
    }
}