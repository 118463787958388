.team__header
{
    margin: 3rem 0 4rem;
    font-size: $h1-font-size;
    font-weight: bold;
    text-align: center;
}

.team__rider
{
    margin-bottom: 2rem;
    transition: all .5s;
    cursor: default;

    &:hover
    {
        box-shadow: 0 0 20px rgba(0, 0, 0, .75);
        background: var(--gray-dark);
        color: var(--white);
    }
}

.team__photo
{
    text-align: center;

    & > .fa
    {
        font-size: 10rem;
    }
}

.team__name
{
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold;
    margin: 0.5rem;
}

.team__question
{
    display: none;
}

@include media-breakpoint-up(md)
{
    .team__rider
    {
        display: flex;
    }
    
    .team__photo
    {
        flex: 0 0 33%;
    }
    
    .team__about
    {
        flex: 0 0 67%;
        padding: 1rem;
    }

    .team__name
    {
        text-align: left;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .team__question
    {
        display: block;
        font-size: 11rem;
        text-align: center;
        line-height: 1;
    }

    .team__hidden
    {
        .team__photo
        {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .team__about
        {
            text-align: center;
        }
    }
}

@include media-breakpoint-up(lg)
{
    .team__rider
    {
        min-height: calc(100% - 2rem);
        padding: 8px;
        background: var(--light);
    }
}

.team__friends
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.friends__rider
{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;

    @include media-breakpoint-up(sm)
    {
        width: 50%;
    }

    @include media-breakpoint-up(md)
    {
        width: 33%;
    }
}

.friends__photo
{
    text-align: center;

    .fa
    {
        font-size: 10rem;
        color: #cecece;
        // text-shadow: 2px 1px 0 #f44336, 4px 3px 0 #00ff2f;
    }
}

.friends__name
{
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin: .5rem 0;
}
