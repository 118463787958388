.faq {
    margin: 30px -7px;

    .section {
        padding: 30px;
        background: #F6F6F6;
        margin: 15px 7px;

        .section-heading {
            margin-bottom: 30px;
            .icon {

            }
            .title {
                font-family: $font-family-2;
                text-align: center;
                padding:10px;
                color: $text-color;
                font-size: 24px;
                font-weight: 600;
            }
        }

        .qa {
            margin-bottom: 15px;
            .question {
                font-size: 14px;
                font-weight: 600;
                color: $text-color-2;
                margin-bottom: 10px;
                line-height: 1.1em;
                &:hover {
                    text-decoration: underline;
                }
            }
            .answer {
                font-size: 14px;
                font-weight: 400;
                color: $text-color-3;
                line-height: 1.3em;
            }
        }

        &.large {
            .qa {
                margin-bottom: 45px;
                .question {
                    font-size: 18px;
                }
                .answer {
                    font-size: 16px;
                }
            }
        }

        .buttons {
            margin-top: 30px;
            margin-bottom: 0px;
        }
    }
}