.image-text {
    //background-image: url('https://scootive.pl/themes/scootive1.1/images/home/bg-wheels.jpg');
    //background-size: cover;
    margin-top: 45px;
    margin-bottom: 45px;

    .image {
        max-height: 350px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    
    .content {
        //padding: 90px 0px;
        padding: 30px;
        background: #F9F9F9;
        //border-top: 1px solid #000;

        .title {
            font-family: $font-family-2;
            font-size: 24px;
            color: $text-color;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .subtitle {
            font-family: $font-family-2;
            font-size: 12px;
            color: $text-color-3;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 15px;
        }
        .description {
            font-size: 14px;
            //color:#FFF;
            line-height: 1.5em;
            color: $text-color-2;
            margin-bottom: 15px;
            letter-spacing: 0.5px;
        }
        .hours {
            font-size: 13px;
            line-height: 1.5em;
            color: #000;
            letter-spacing: 0.5px;
            b {
                font-weight: 600;
            }
        }
        .buttons {
            text-align: left;
        }
    }
}