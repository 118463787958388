#cookie-alert
{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;

    background: $color-accent;
    color: #fff;

    p
    {
        margin: 0;
        padding: 8px 15px 8px 0;
        line-height: 1.5em;
        font-size: 12px;
    }

    a
    {
        text-decoration: underline;

        &:hover
        {
            text-decoration: none;
        }
    }
}

#cookie-button
{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: transparent;
    border: 0;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
}
