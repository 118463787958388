.products
{
    margin-bottom: 30px;

    .product-image
    {
        position: relative;
        padding: 60% 0;
        background: #F3F3F3;
        margin-bottom: 10px;
        z-index: 2;

        &.hot-deal
        {
            background-color: #feffd2;
        }
    }
    
    .product {
        display: block;
        position: relative;
        z-index: 1;
        margin-bottom: 16px;

        .product-prices {
            .product-price {
                font-size: 12px;
            }
        }
        .product-variants {
            position: absolute;
            bottom: 0px;
            width: 100%;
            max-height: 0px;
            height: 0px;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.95);
            transition: max-height 0.6s, height 0.6s;
            -webkit-transition: max-height 0.6s, height 0.6s;
            z-index:4;

            .product-variants-inner {
                padding: 15px;
                .title {
                    font-size: 12px;
                    margin-bottom: 10px;
                }
                .toomuch {
                    font-size: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $text-color-3;
                }
                ul {
                    &.list {
                        .list-element {
                            font-size: 13px;
                            margin-bottom: 5px;
                        }
                    }
                    &.inlines {
                        .inline-element {
                            font-size: 13px;
                            margin-bottom: 5px;
                            margin-right: 5px;
                            float: left;
                        }
                    }
                }
            }
        }
        &:hover {
            .badges {
                opacity: 0.8;
            }

            .product-variants {
                transition: max-height 0.6s, height 0.6s;
                max-height: 200px;
                height: auto;
            }
        }
    } // product

    .product-sm {
        .product {
            .badges {
                display: none;
            }
            .product-variants {
                display: none;
            }
        }
    }

    .products-tip {
        padding: 30px;
        background: #F9F9F9;
        margin-top: 15px;
        &:after {
            content: "";
            display: block;
            clear:both;
        }
        .icon {
            width: 48px;
            height: 48px;
            float: left;
            margin-right: 30px;
            opacity: 0.5;
            img {
                width: 48px;
                height: 48px;
            }
        }
        .description {
            width: calc(100% - 90px);
            float: left;
            color:$text-color-3;
            font-size: 12px;
            line-height: 1.4em;
        }

    } //products-tip
}

.product-variant-name
{
    font-size: .9rem;
}

.badges
{
    position: absolute;
    top:0px; left: 0px;
    z-index: 4;
    opacity:0.4;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;

    & > div
    {
        padding: 8px;
        font-size: 9px;
        text-transform: uppercase;
        font-family: $font-family-2;
        font-weight: 600;
        width: auto;
        color: #fff;

        &.new
        {
            background: $color-accent;
        }

        &.ship
        {
            background: $color-accent-2;
        }

        &.sale
        {
            background: $color-promo;
        }
    }
}

.product-image
{
    img
    {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        mix-blend-mode: darken;
        z-index: 3;
        transition: all .5s;
    }

    .weight
    {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;
        padding: 3px 5px;
        font-size: .8em;
        background: #b5b5b5;
        color: #000;
        opacity: 0.4;
        border-top-left-radius: 2px;
    }

    .first
    {
        display: block;
    }

    .second
    {
        opacity: 0;
    }

    .extra
    {
        position: absolute;
        transform-origin: bottom right;
        transform: rotate(-90deg) translate(35px, 0);
        padding: 5px 20px;
        font-size: 1.2rem;
        background: rgba($red, .8);
        color: #fff;
        top: 0;
        right: 0;
        text-transform: uppercase;
        transition: all .25s;
    }

}

.product
{
    .product-details
    {
        margin-bottom: 5px;
        line-height: 1;
    }

    &:hover
    {
        .first
        {
            opacity: 0;
            transform: perspective(10px) translate3d(0, 0, -100px);
        }

        .second
        {
            opacity: 1;
        }

        .extra
        {
            background: $red;
        }
    }
}

.product-details
{
    .product-name
    {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .product-brand
    {
        color: #666;
        font-size: 12px;
        font-weight: 400;
    }
}
