.form {

    .heading {
        //text-align: center;
        margin-bottom: 15px;
        .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 3px;
            line-height: 1;
        }
        .subtitle {
            color: $text-color-3;
            font-size: 12px;
            margin-bottom: 10px;
        }

        .description {
            margin: 5px 0px;
            font-size: 12px;
        }
    }


    .register {

        &.hide-pass {
            #passwords {
                display: none;
            }
        }
    }

}