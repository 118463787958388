.popup
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #000;
    background: rgba(0,0,0,.55);

    z-index: 9999;

    &.closed
    {
        display: none;
    }
}

.popup-window
{
    position: relative;
    background: #fff;
    max-width: 90vw;
    max-height: 90vh;
    box-shadow: 0 0 15px rgba(0,0,0,.75);
}

.popup-close
{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    button
    {
        box-sizing: content-box;

        width: 30px;
        height: 30px;

        padding: 1px;
        
        border: 0;
        border-radius: 50%;

        background: #000;
        background: rgba(0,0,0,.55);

        &:hover
        {
            background: rgba(0,0,0,.75);
            cursor: pointer;
        }
    }

    span
    {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #fff;
    }
}

.popup-image
{
    max-width: 100%;
    max-height: 90vh;
    width: auto;
    height: auto;
}

.visible-portrait
{
    display: none;

    @media (orientation: portrait)
    {
        display: block;
    }
}

.hidden-portrait
{
    display: block;

    @media (orientation: portrait)
    {
        display: none;
    }
}
