.label
{
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    border-radius: .25em;
    color: #fff;
}

.label-default
{
    background: #000;
}

.label-info
{
    background: $color-accent-2;
}

.label-danger
{
    background: $color-danger;
}

.label-success
{
    background: $color-success;
}
