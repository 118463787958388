#addresses {

    max-width: 640px;
    margin:0px auto 45px;

    .heading {
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #DDD;
        .title {
            font-size: 16px;
            font-family: $font-family-2;
            font-weight: 600;
        }
    }

    .address {
        display: block;
        padding: 15px;
        border: 1px solid #EEE;
        @include border-radius(5px);
        margin-bottom: 10px;
        .text {
            font-size: 18px;
            color: $text-color-2;
            small {
                display: block;
                font-size: 14px;
                color: $text-color-3;
            }
        }
        .btn {
            margin-bottom: 0px;
        }
    }
}