.price {
    font-size: 16px;
    color:#000;
    font-weight: bold;
    .regular {
        text-decoration: line-through;
        margin-right: 5px;
    }
    .promo {
        color: $color-promo;
    }
}
.price-hint {
    color: $color-accent;
    font-weight: bold;
    margin-top:5px;
}