@media only screen and (min-width: $header-mobile-breakpoint + 1)
{
    #product-menu
    {
        .category-important
        {
            &>a
            {
                border-top: 1px solid #000;
            }
        }
    }

    #searchIconMobile
    {
        display: none;
    }

    #header-top
    {
        display: block;
        width: 100%;
        height: $header-top-height;
        background: $color-accent;

        .header-top-element
        {
            font-size: 12px;
            font-weight: bold;
            color:#fff;
            line-height: $header-top-height;
        }
    }

    #header-search
    {
        float: left;
        border: 1px solid $border-color;
        margin-top: 18px;
        margin-left: 48px;
        width:240px;

        >form
        {
            position: relative;
        }

        input
        {
            float: left;
            padding: 8px 16px;
            width:calc(100% - 34px);
            font-size: 13px;
            border:0px;
            outline: 0px;
            font-family: $font-family;
        }
    }

    #header-search-btn
    {
        float: left;
    }

    header
    {
        #header-content
        {

            .menu-btn {
                display: none;
            }

            .cart-btn {
                display: none;
            }

            #header-help {
                width: 274px;
                height: ($header-height - $header-top-height - 8px);
                margin-top: 8px;
                float: right;

                #header-help-contact {
                    border-bottom:1px solid #666;
                    font-size: 13px;
                    line-height: 28px;
                    overflow: visible;
                    float: right;
                    .spacer {
                        display: inline-block;
                        width: 1px;
                        height: 10px;
                        background: #999;
                        margin: 0px 10px;
                    }
                }

                #header-help-menu {
                    padding-left:-12px;
                    & > ul {
                        margin-top: 0px;
                        list-style: none;
                        font-size: 13px;
                        float:right;
                        & > li {
                            float: left;
                            margin-left: 12px;
                            padding-bottom: 2px;
                            & > a {
                                line-height: 28px;
                                &:hover {
                                    color: $text-color-2;
                                }
                            }
                        }
                        li {
                            position: relative;
                            .dropdown {
                                display: none;
                                position: absolute;
                                top: 29px;
                                right: 0px;
                                background: #FFF;
                                border:1px solid #666;
                                z-index: 14;
                                padding: 15px;
                                &.list {
                                    width: 120px;
                                    list-style: none;
                                    .title {
                                        font-weight: 600;
                                        padding-bottom: 4px;
                                        border-bottom: 1px solid #EEE;
                                        margin-bottom: 8px;
                                    }
                                    li {
                                        display: block;
                                        float: none;
                                        padding: 4px;
                                        img {
                                            display: inline-block;
                                            padding-right: 5px;
                                        }
                                        a {
                                            display: block;
                                            .currency-sign {
                                                display: inline-block;
                                                width:16px;
                                                padding-right: 5px;
                                                color:#000;
                                            }
                                            &:hover {
                                                color: $text-color-2;
                                            }
                                        }
                                    }
                                }
                                &.wide {
                                    width: 240px;
                                }
                                &.space {
                                    li {
                                        margin: 5px 0px;
                                    }
                                }
                                &.box {
                                    width: 400px;
                                    .box-element {
                                        display: block;
                                        margin: 8px 16px;
                                        .title {
                                            margin-top:10px;
                                            text-align: center;
                                            font-size: 13px;
                                        }
                                        .image {
                                            text-align: center;
                                            img {
                                                width: 32px;
                                            }
                                            &.big {
                                                img {
                                                    width: 72px;
                                                }
                                            }
                                            &.mid {
                                                img {
                                                    width: 38px;
                                                }
                                            }
                                        }
                                        .description {
                                            font-size: 12px;
                                            line-height: 1.5em;
                                            h6 {
                                                font-weight: 600;
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                    .spacer {
                                        margin: 12px 0px;
                                        height: 1px;
                                        background: #DDD;
                                    }
                                    .link {
                                        font-size: 12px;
                                    }
                                    a:hover {
                                        opacity: 0.5;
                                    }
                                }
                            }
                            &:hover {
                                .dropdown {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-menu {

            & > ul {
                
                &:after {
                    display: block;
                    content: "";
                    clear: both;
                }
                & > li {
                    float: left;
                    .dropdown {

                        font-family: $font-family-2;
                        display: none;
                        position: absolute;
                        top: 38px;
                        background: $main-menu-text-color;
                        border-top:1px solid transparent;
                        border-left:1px solid $main-menu-color;
                        border-right:1px solid $main-menu-color;
                        border-bottom:1px solid $main-menu-color;
                        z-index: 13;
                        padding: 15px;
                        width: 150px;

                        &.wide2{
                            width: 300px;
                        }
                        &.wide3{
                            width: 450px;
                        }
                        &.wide4{
                            width: 600px;
                        }
                        

                        li {
                            margin-bottom: 1px;
                            font-size: 12px;

                            a {
                                color:#333;
                                &:hover {
                                    color:#666;
                                }
                            }
                        }
                    }
                }
            }
            #product-menu {
                float: left;
                margin-bottom: 0;
            }
            #cart-menu {
                float: right;
                margin-bottom: 0;
            }
            #info-menu {
                float: left;
                margin-bottom: 0;
            }
        }

        #header-main-menu {
            background: #000;
            & > ul > li {
                & > a {
                    padding: 0px 14px;
                    font-size: 12px;
                    font-weight: 500;
                    border: 1px solid transparent;
                }
                &:hover {
                    & > a {
                        background: $main-menu-text-color;
                        color:#000;
                        border-top: 1px solid $main-menu-color;
                        border-left: 1px solid $main-menu-color;
                        border-right: 1px solid $main-menu-color;
                        border-bottom: 1px solid $main-menu-text-color;
                    }
                    .dropdown {
                        display: block;
                    }
                }
            }
        }

        #header-info-menu {
            //background: #EEE;
            border: 1px solid #555;

            .mobile-title,
            #account-menu,
            #local-select {
                display: none;
            }

            & > ul > li {
                & > a {
                    padding: 0px 15px;
                    font-size: 11px;
                    font-weight: 400;
                    border: 1px solid transparent;
                    color: $text-color;
                }
                &:hover {
                    & > a {
                        background: $main-menu-text-color;
                        color:#000;
                    }
                    .dropdown {
                        display: block;
                    }
                }
            }
        }

    }

    #header-content
    {
        .scooter-service-box
        {
            float: left;
            margin-top: 18px;
            margin-left: 28px;
        }
    }
}

.btn-scooter-service
{
    background: #ffeb3b;
    font-size: .8rem;
    padding: .3rem;
}
