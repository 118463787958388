.img-product-preview
{
    align-self: center;
    max-width: 100%;
    width: auto;
    margin: auto;

    @media (min-width: map-get($grid-breakpoints, lg))
    {
        max-height: calc(100vh - 138px);
    }
}

#product-preview
{
    display: flex;
    flex-direction: column;
    align-content: stretch;
    padding: 0;

    @media (min-width: map-get($grid-breakpoints, lg))
    {
        height: 100vh;
    }

    .carousel-inner
    {
        display: flex;
        flex: 1 0 auto;
        align-items: stretch;
        padding: 5vh 0;
    }
    
    .carousel-item
    {
        &>div
        {
            display: flex;
            // align-items: center;
            height: 100%;
        }
    }

    .important-info
    {
        margin: 1rem 0;
        color: $red;
        text-align: center;
        font-weight: bold;
    }
}


.product-thumbs
{
    width:calc(100% - 60px);
    margin: 0 auto;
    position: relative;

    li
    {
        margin: 0 5px;
        background: #f3f3f3;
        cursor: pointer;
        height: auto;
        position: relative;

        &:focus
        {
            outline: 0;
        }
    }

    img
    {
        max-width: 100%;
        max-height: 100%;
        mix-blend-mode: darken;
    }

    @media (min-width: map-get($grid-breakpoints, lg))
    {
        height: 138px;

        li
        {
            height: 138px;
        }

        img
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    button
    {
        position: absolute;
        background: transparent;
        border:0px;
        width:24px;
        top: 0;
        bottom: 0;
        line-height: 24px;
        font-size: 32px;
        outline: none;
        cursor: pointer;
        font-family: arial;

        &.slick-prev {
            left:-24px;
        }
        &.slick-next {
            right:-24px;
        }
        &.slick-disabled {
            opacity: 0.25;
        }
    }
}

#product {
    margin-bottom: 45px;

    .add-to-cart
    {
        margin-bottom: 10px;

        .btn
        {
            padding: 20px;
            font-size: 15px;
        }
    }


    .image {
        display: block;
        text-align: center;

        img {
            width: 100%;
        }
    }

    .product-form {
        margin-bottom: 45px;

        .inline-input {
            display: inline-block;
            margin-right: 10px;
        }
        
        .product-option {
            margin-bottom: 25px;
        }

        .buttons {
            text-align: left;
            margin: 0px;
            .btn {
                width: 100%;
                line-height: 9px;
            }
        }

    }

    .product-addons
    {
        margin-bottom: 45px;

        .title
        {
            font-size: 12px;
            color: #666;
            margin-bottom: 15px;
        }


        li
        {
            display: block;
            margin-bottom: 5px;

            &>a
            {
                line-height: 32px;
                display: flex;
                align-items: center;
            }

            img
            {
                width: 32px;
                height: 32px;
                margin-right: 15px;
            }
        }
    }

    .product-tabs {
        .tabs-nav {
            ul {
                &:after {
                    clear:both;
                    content: "";
                    display: block;
                }
                li {
                    float: left;
                    a {
                        padding: 10px 15px;
                        text-transform: uppercase;
                        font-size: 10px;
                        font-weight: bold;
                        border: 1px solid transparent;
                        display: block;
                    }
                    &.active {
                        a {
                            border-right: 1px solid #000;
                            border-left: 1px solid #000;
                            border-top: 1px solid #000;
                            border-bottom: 1px solid #FFF;
                        }
                    }
                }
            }
        }
        .tabs-content {
            margin-top: -1px;
            .tab-content {
                padding: 15px;
                border: 1px solid #000;
                line-height: 1.5em;
                letter-spacing: 0.5px;
                font-size: 12px;
                display: none;
                &.active {
                    display: block;
                }

            }
        }
    }
}

.col-crosssell
{
    margin: 15px 0;

    .title
    {
        font-size: 14px;
        font-weight: bold;
        color: $text-color-black;
        margin-bottom: 4px;
    }

    // &>div
    // {
    //     border: 1px solid #a9a9a9;
    // }
}

.product-crosssell
{
    margin: 5px 0;
    border: 1px solid #ddd;
    // border-left: 2px solid #efefef;
    // border-right: 2px solid #efefef;

    .title
    {
        font-size: 12px;
        color: #666;
        margin-bottom:3px;
    }
}

.product-crosssell-wrapper
{
    padding: 10px;

    .row
    {
        display: flex;
    }

    .col-image
    {
        // background: #f3f3f3;

        img
        {
            mix-blend-mode: darken;
        }
    }

    .col-info
    {
        display: flex;

        &>.row
        {
            flex-direction: column;
            flex-grow: 1;
        }
    }

    .form-default
    {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex-grow: 1;
    }

    .product-crosssell-price,
    .product-crosssell-selected
    {
        flex: 1 0 auto;
    }

    .segment
    {
        border: 3px solid #f3f3f3;
        border-radius: 2px;
    }

    .custom-control-label
    {
        font-size: .8rem;
        font-weight: bold;
    }
}

.btn-crosssell
{
    border: 1px solid $color-accent-2;
    background: #fff;
    color: $color-accent-2;
    margin-bottom: 0;
    width: auto !important;

    &:hover
    {
        background: $color-accent-2;
        color: #fff;
    }
}

.product-crosssell-name
{
    font-size: 12px;
    font-weight: bold;
    font-family: $font-family-2;
    color:#333;
    display: block;
    margin-bottom:5px;
}

.product-crosssell-selected
{
    font-size: 12px;
    color: $text-color-3;
}

.product-crosssell-price {
    font-size: 11px;
    font-weight: bold;
    color:#333;
    display: block;

    .regular {
        text-decoration: line-through;
        margin-right: 5px;
    }
    .promo {
        color: $color-promo;
    }
    .free {
        color: $color-accent-2;
    }
}

.product-crosssell-select {
    margin-top:10px;

    .border-select {
        select {
            border: 1px solid #EEE;
            color: #333;
            font-size: 11px;
        }
        //background: #FAFAFA;
    }
}

.product-details
{
    margin-top:30px;

    .title
    {
        font-family: $font-family-2;
        font-size: 24px;
        text-transform: uppercase;
        color: #000;
        letter-spacing: 2px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 1;
    }

    .brand
    {
        font-family: $font-family-2;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: bold;
        color: #999;
        margin-bottom: 15px;
    }

    .price
    {
        font-size: 16px;
    }

    .price-hint {
        font-size: 12px;
    }

    .weight
    {
        font-family: $font-family-2;
        font-weight: bold;
        text-transform: uppercase;
        margin: 1.5em 0;
        color: #000;

        &>span
        {
            font-weight: normal;
        }
    }
}

.price-old
{
    text-decoration: line-through;
}

.price-promo
{
    color: $color-promo;
}

.product__shipping_important
{
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 10px;
}

.product__st_table
{
    display: table;
    width: 100%;
}

.product__st_row
{
    display: table-row;

    &:hover
    {
        background: $text-color-3;
    }
}

.product__st_header
{
    font-weight: bold;
}

.product__st_row > div
{
    display: table-cell;
    padding: 2px;
}

.product__st_name
{
    width: 60%;
}

.product__st_value
{
    text-align: right;
}

.cs-products
{
    @media (min-width: map-get($grid-breakpoints, lg))
    {
        margin: 0 20px;
    }

    &>.row
    {
        border-bottom: 1px solid $border-color;
        padding: 20px 0;

        &:last-child
        {
            border-bottom: 0;
        }
    }

    .row-select
    {
        margin-top: 10px;
    }

    .product-name
    {
        font: 1.6em $font-family-2;
        margin-bottom: .7em;
        line-height: 1em;
    }

    .price
    {
        font-weight: normal;
    }

    .title
    {
        font-size: 12px;
        color: $text-color-3;
    }

    a
    {
        &:hover
        {
            text-decoration: underline;
        }
    }
}

.ov-presentation
{
    position: relative;
    width: 100%;
    padding-top: 100%;

    @media screen and (min-width: map-get($grid-breakpoints, lg))
    {
        padding: 0;
        height: 100%;
    }
}

.ov-wrapper
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;

    @media screen and (min-width: map-get($grid-breakpoints, lg))
    {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.slick-initialized
{
    .slick-slide
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
    }
}

.slick-track
{
    display: flex;
}
