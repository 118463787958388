.job__header
{
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    font-size: 3rem;
    background-image: url('/gfx/job/workers.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 2;

    & > h1
    {
        padding: 4rem 1rem;
        color: white;
        background: rgba(0, 0, 0, .6);
    }
}

.job__benefits_list
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.job__benefits
{
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 15px 0;
    width: 100%;
    
    &>p
    {
        line-height: 1px;
        font: bold 1rem $font-family;
        text-transform: uppercase;
        margin: 0 0 0 44px;
    }
}

.job__benefits_independent
{
    background-image: url('/gfx/job/icon-independent.svg');
}

.job__benefits_development
{
    background-image: url('/gfx/job/icon-development.svg');
}

.job__benefits_atmosphere
{
    background-image: url('/gfx/job/icon-atmosphere.svg');
}

.job__benefits_unique
{
    background-image: url('/gfx/job/icon-unique.svg');
}

.job__benefits_salary
{
    background-image: url('/gfx/job/icon-salary.svg');
}

.job__h2
{
    font: bold 2.5rem $font-family-2;
    text-transform: uppercase;
    text-align: center;
    margin: 3.5rem 0 2rem;
}

.job__positions
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.job__position
{
    background: #eee;
    padding: 20px 25px;
    width: 100%;
    margin: 20px 0;
}

.job__position_header
{
    border-bottom: 1px solid black;
    margin: 1rem 0;

    & > h3
    {
        text-transform: uppercase;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 900;
        margin: .8rem 0 .5rem;
    }
}

.job__position_icon
{
    border-radius: 50%;
    width: 50%;
    margin: auto;
    padding: 12%;
    border: 4px solid black;

    & > img
    {
        width: 100%;
        height: auto;
    }
}

ul.job__position_activities
{
    & > li:before
    {
        content: '- ';
    }
}

.job__position_apply
{
    display: block;
    max-width: max-content;
    text-transform: uppercase;
    font-weight: 900;
    padding: .25rem 1.5rem;
    margin: 1rem auto;
    background: $red;
    color: white;

    &:hover
    {
        color: white;
        text-decoration: underline;
    }
}

@media (min-width: map-get($grid-breakpoints, sm))
{
    .job__position
    {
        width: calc(50% - 20px);
        margin: 20px 10px;
    }
}

@media (min-width: map-get($grid-breakpoints, md))
{
    .job__benefits
    {
        width: 300px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (min-width: map-get($grid-breakpoints, lg))
{
    .job__position
    {
        width: calc(33% - 40px);
        margin: 20px;
    }

    .job__benefits
    {
        width: 290px;
    }
}

.job_offer
{
    h4
    {
        font-size: medium;
        font-weight: bold;
    }

    ul
    {
        list-style-type: disc;
        padding-left: 2rem;
        margin-bottom: 1rem;
    }
}
