.brands {

    margin: 30px auto;

    .heading {
        margin-bottom: 15px;
        .title {
            font-family: $font-family-2;
            text-align: center;
            padding:10px;
            color: $text-color;
            font-size: 32px;
            font-weight: 600;
        }
    }

    .letters {
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #DDD;

        a {
            display: inline-block;
            margin: 5px;
            font-size: 20px;
            font-weight: 600;
            color: $text-color-2;
            &:hover {
                color: $text-color;
            }
        }
    }

    .list {
        margin: 45px auto;
        max-width: 700px;

        .letter-section {
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #EEE;

            .large-letter {
                font-size: 84px;
                font-weight: 600;
                color: #EEE;
                margin-bottom: 15px;
                text-align: center;
            }

            .brand-name {
                font-size: 13px;
                letter-spacing: 0.4px;
                color: #000;
                margin: 8px;
                display: block;
                &:hover {
                    text-decoration: underline;
                }
            }

        }

    }

}