#alert
{
    .alert
    {
        margin: 1rem 0;
    }
}

@keyframes flicker
{
	0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
		text-shadow: none;
	}
	20%, 24%, 55% {
		
		text-shadow: 0 0 5px #00000080;
	}

}

@keyframes important-alert
{
	0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
		box-shadow: 0 0 10px var(--danger);
		opacity: 1;
	}

	20%, 24%, 55% {
		box-shadow: 0 0 10px #e6221880;
		opacity: .75;
	}
}

.alert-blink-neon
{
	animation: 3s infinite important-alert;
}
