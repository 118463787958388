footer {
    padding: 30px 0px;
    //border-top: 4px solid #000;
    background: #EEE;

    .footer-col {
        margin-bottom: 30px;
        .title {
            font-weight: 600;
            font-family: $font-family-2;
            color: #666;
            font-size: 16px;
            margin-bottom: 12px;
        }
        .description {
            font-size: 13px;
            line-height: 1.5em;
            color: #666;
        }

        ul
        {
            list-style: none;
            line-height: 1;
        }

        li
        {
            margin-bottom: 10px;
            color: #666;
            font-size: 13px;

            &.huge {
                font-size: 18px;
                margin-bottom:10px;
                i {
                    width: 20px;
                    font-size: 19px;
                    margin-right: 5px;
                }
            }
        }

        a
        {
            font-size: inherit;

            &:hover
            {
                border-bottom: 1px solid #aaa;
            }
        }
    }

    .footer-text {
        margin: 30px 0px 0px;
        text-align: center;
        color: #666;
        font-size: 11px;
    }
}