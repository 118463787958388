#help
{
    margin-top: 30px;
}

.help-wrapper
{
    max-width: 840px;
    margin: 0px auto 45px;

    .heading
    {
        text-align: center;
        padding: 15px;

        .title
        {
            font-family: $font-family-2;
            font-weight: 600;
            color: $text-color;
            font-size: 24px;
        }
    }

}

.text-block
{
    font-size: 14.5px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0.4px;
    color: $text-color;
    margin-bottom: 30px;

    a
    {
        font-weight: bold;
        text-decoration: none;

        &:hover
        {
            text-decoration: none;
        }
    }
}

.table-shipping
{
    width: 100%;

    thead>tr:first-child>th,
    tfoot td
    {
        border: none;
    }

    tfoot
    {
        text-align: right;
    }

    th, td
    {
        padding: 2px 5px;
    }

    th
    {
        border: 1px solid black;
    }

    td
    {
        border: 1px solid black;
    }
}
