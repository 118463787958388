
#header-top
{
    display: none;
}

#searchIconMobile
{
    position: absolute;
    top: calc(50% - 16px);
    right: 40px;
    width: 32px;
    height: 32px;
    border: 0;
    background: transparent;
}

.search-input
{
    display: inline-block;
    width: calc(100% - 36px);
}

#header-search-btn
{
    width: 32px;
    border: none;
    background: transparent;
    color: $text-color;
}

header {
    width:100%;

    #header-content {
        position: relative;

        &:after {
            content: "";
            display: block;
            clear:both;
        }

        #logo {
            position: relative;
            float: left;
            display: block;
            top: 18px;
            width: 135px;
            height: 32px;
            img {
                width: 135px;
                height: 32px;
            }
        }

    }

    .main-menu {
        font-family: $font-family-2;
        &:after {
            display: block;
            content: "";
            clear: both;
        }
        & > ul {
            &:after {
                display: block;
                content: "";
                clear: both;
            }
            & > li {
                position: relative;
                & > a {
                    display: inline-block;
                    font-weight: bold;
                    i {
                        position: relative;
                        top:-1px;
                    }
                }
                .dropdown {
                    
                    .all {
                        font-weight: 500;
                        margin-top: 5px;
                        color: #000;
                        font-size: 12px;
                        margin-bottom: 15px;
                        display: block;
                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .title {
                        font-size: 11px;
                        font-weight: bold;
                        color: #333;
                        padding-bottom: 4px;
                        margin-bottom: 16px;
                        border-bottom: 1px solid #EEE;
                    }

                }
            }
        }
    }

    #header-main-menu {
        & > ul > li {
            & > a {
                line-height: 36px;
                color:$main-menu-text-color;
                text-transform: uppercase;
            }
        }
    }

    #header-info-menu {
        & > ul > li {
            & > a {
                line-height: 28px;
                color: #777;
                text-transform: uppercase;
            }
        }
    }

}

.info-menu-job
{
    font-weight: bold !important;
    color: $color-accent !important;
}
