#product-menu
{
    .category-important
    {
        background: $color-promo;
    }
}

@media only screen and (max-width: $header-mobile-breakpoint) {
    #header-search
    {
        position: absolute;
        top: -100%;
        width: 100%;
        padding: .5rem 0;
        background: #fff;
        border-bottom: 1px solid #ddd;
        transition: all .3s;
        opacity: 0;
    
        &.show
        {
            top: calc(100% + 1px);
            opacity: 1;
        }
    }
    
    header {
        background: #FFF;
        position: fixed;
        top:0px; left:0px;
        z-index: 9;

        #header-content {
            
            height: $header-mobile-height;
            border-bottom: 1px solid #DDD;

            .menu-btn {
                display: block;
                position: absolute;
                top: calc(50% - (20px / 2));
                left: 0px;
                width: 24px;
                height: 24px;
                background: transparent;
                outline: 0;
                border: 0px;

                span {
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: #000;
                    top:11px; left: 0px;
                    -webkit-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    transition: all 0.3s;
                    &:before,
                    &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left:0px;
                    width: inherit; height: inherit;
                    background: inherit;
                    -webkit-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    transition: all 0.3s;
                    }
                    &:before {
                        top: -5px;
                    }
                    &:after {
                        top: 5px;
                    }
                }
            }

            .cart-btn {
                display: block;
                position: absolute;
                width: 32px;
                height: 32px;
                top:calc(50% - 16px);
                right: 0px;
                img {
                    width: 32px;
                }
            }

            #logo {
                position: absolute;
                top:calc(50% - 13px);
                left: calc(50% - 55px);
                width: 110px;
                height: 26px;
                img {
                    width: 110px;
                    height: 26px;
                }
            }

            #header-help {
                display: none;
            }
        }

        .main-menu {
        
            & > ul {
                overflow-y: auto;
                position: absolute;

                & > li {
                    display: block;
                    width: 100%;
                    float: none;

                    & > a {
                        transition: color .5s;
                        -webkit-transition: color .5s;
                        -ms-transition: color .5s;
                    }

                    .dropdown {
                        display: block;
                        position: static;
                        border: 0px;
                        overflow: hidden;
                        padding: 0;
                        max-height: 0px;
                        margin-bottom: 0px;
                        transition: max-height .5s, margin-bottom .5s;
                        -webkit-transition: max-height .5s, margin-bottom .5s;
                        -ms-transition: max-height .5s, margin-bottom .5s;

                        .all {
                            font-size: 12px;
                        }

                        .title {
                            margin-top: 15px;
                            margin-bottom: 5px;
                        }

                        li {
                            a {
                                padding:5px 0px;
                                display: block;
                            }
                        }
                    }

                    &.dropped {
                        .dropdown {
                            transition: max-height 0.8s ease-in;
                            max-height: 9999px;
                            margin-bottom: 15px;
                        }
                    }
                }

                &.submenu-selected {
                    & > li {
                        & > a {
                            color: #CCC !important;
                        }

                        &.dropped {
                            & > a {
                                color: #000 !important;
                            }
                        }
                    }
                }

            }
        }

        #header-main-menu {
            position: absolute;
            left: -100%;

            top: $header-mobile-height;

            z-index: 9;
            width: 100%;
            height: calc(100vh - #{$header-mobile-height});
            background: rgba(0, 0, 0, 0);

            transition: background-color .3s, left .3s;
            -webkit-transition: background-color .3s, left .3s;
            -ms-transition: background-color .3s, left .3s;

            #product-menu {
                background: #FFF;
                width: 50%;
                //max-width: 300px;
                height: calc(100vh - #{$header-mobile-height});
                padding: 15px 0 30px;

                left: -100%;

                transition: left .3s;
                -webkit-transition: left .3s;
                -ms-transition: left .3s;            

                & > li {
                    display: block;
                    & > a {
                        color: #000;
                        display: block;
                        width: 100%;
                        font-size: 14px;
                        padding: 0 0 0 16px;
                    }
                }
            }

            #cart-menu {
                display: none;
            }
        }

        #header-info-menu {
            position: absolute;
            right: -100%;
            width: 50%;
            z-index: 10;
            top: $header-mobile-height;

            transition: right .3s;
            -webkit-transition: right .3s;
            -ms-transition: right .3s;      

            background: #FFF;
            height: calc(100vh - #{$header-mobile-height});
            padding-top: 15px;
            padding-bottom: 30px;
            padding-left: 16px; // padding-left: $grid-gutter-width-base;
            border-left:1px solid lighten($border-color, 6%);

            .mobile-title {
                display: block;
                font-size: 11px;
                font-weight: bold;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            #local-select {
                width:calc(100% - 16px);
                .title {
                    font-size: 11px;
                    margin-bottom: 2px;
                }
                .select {
                    margin-bottom: 10px;
                }
            }

            ul {
                width: 100%;
                position: relative;
                margin-bottom: 30px;
                & > li {
                    display: block;
                    & > a {
                        color: $text-color-2;
                        display: block;
                        width: 100%;
                        font-size: 11px;
                        padding: 0px;
                    }
                }
            }

        }

    }

    #header-spacer {
        height: $header-mobile-height;
    }



    // States
    body.product-menu-active {
        header {
            #header-content .menu-btn span {
                background: #FFF;
                &:before {
                    top: 0px;
                    transform: rotate(45deg);
                    background: #000;
                }
                &:after {
                    top: 0px;
                    transform: rotate(-45deg);
                    background: #000;
                }
            }
            #header-main-menu {
                left: 0px;
                background: rgba(0, 0, 0, .35);
                #product-menu {
                    left: 0px;
                }
            }
            #header-info-menu {
                right: 0px;
            }
        }
    }

}