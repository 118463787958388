.info-boxes {
    margin-top: 60px;
    margin-bottom: 60px;

    .col-info-box
    {
        display: flex;
    }

    .info-box {
        align-items: stretch;
        // position: relative;
        display: flex;
        width: 100%;
        // float: left;
        // margin-bottom: 15px;

        // &:before {
        //     content:'';
        //     float:left;
        //     padding-top:100%;
        // }
    }

    .info-box-content {
        // position: absolute;
        // top: 0px; left:0px;
        // width: 100%; height: 100%;
        padding: 30px;
        width: 100%;
        // .icon {

        // }
        .title {
            font-family: $font-family-2;
            font-size: 22px;
            line-height: 1.2em;
            font-weight: bold;
            color: #FFF;
            margin-bottom: 0px;
        }
        .subtitle {
            font-size: 14px;
            line-height: 1.2em;
            font-weight: bold;
            color: #FFF;
            margin-bottom: 10px;
        }
        .description {
            font-size: 13px;
            font-weight: 500;
            line-height: 1.5em;
            color: #FFF;
            letter-spacing: 0.75px;
        }
    }

    .info-box-color-1 {
        .info-box-content {
            background: $color-accent;
            &:hover {
                background: darken($color-accent, 15%);
            }
        }
    }
    .info-box-color-2 {
        .info-box-content {
            background: #1aa2d1;
            &:hover {
                background: darken(#1aa2d1, 15%);
            }
        }
    }
    .info-box-color-3 {
        .info-box-content {
            background: $color-accent-3;
            &:hover {
                background: darken($color-accent-3, 15%);
            }
        }
    }
    .info-box-color-4 {
        .info-box-content {
            background: $color-accent-4;
            &:hover {
                background: darken($color-accent-4, 15%);
            }
        }
    }
}